import React from "react";
import PropTypes from "prop-types";
import Loadable from "react-loadable";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthRoute from "./components/AuthRoute";
import AppliedRoute from "./components/AppliedRoute";
import PageLoader from "./components/PageLoader/PageLoader";
import {
  appRoutes,
  userSessionCookie,
  PAGE,
  CHILDREN_SIDENAV_CATALOUGUE_IDS,
  CHILDREN_SIDENAV_CUSTOMIZE_IDS,
  CHILDREN_LOYALTY_IDS,
  getRedirectionRoute,
} from "./utils/config";
import { userPermissions } from "utils/permissions";

const delayTime = 60;

// Landing Page
const LandingPage = Loadable({
  loader: () => import("./pages/LandingPage/LandingPage"),
  loading: PageLoader,
  delay: delayTime,
});

// Terms Of Service Agreement

const TermsOfServiceAgreement = Loadable({
  loader: () =>
    import("./pages/TermsOfServiceAgreement/TermsOfServiceAgreement"),
  loading: PageLoader,
  delay: delayTime,
});

// Login
const LoginPage = Loadable({
  loader: () => import("./pages/Login/Login"),
  loading: PageLoader,
  delay: delayTime,
});

// Signup
const SignupPage = Loadable({
  loader: () => import("./pages/Signup/Signup"),
  loading: PageLoader,
  delay: delayTime,
});

// Forgot Password
const ForgotPasswordPage = Loadable({
  loader: () => import("./pages/ForgotPassword/ForgotPassword.js"),
  loading: PageLoader,
  delay: delayTime,
});

// Reset Password
const ResetPasswordPage = Loadable({
  loader: () => import("./pages/PasswordReset/PasswordReset"),
  loading: PageLoader,
  delay: delayTime,
});

// Dashboard
const DashPage = Loadable({
  loader: () => import("./pages/DashIndex"),
  loading: PageLoader,
  delay: delayTime,
});

// Customers List
const CustomerListPage = Loadable({
  loader: () => import("./pages/CustomerList/CustomerList"),
  loading: PageLoader,
  delay: delayTime,
});

const CustomerDetailsPage = Loadable({
  loader: () => import("./pages/CustomerList/CustomerDetailsPage"),
  loading: PageLoader,
  delay: delayTime,
});

// Orders List
const OrdersListPage = Loadable({
  loader: () => import("./pages/OrdersList/OrdersList"),
  loading: PageLoader,
  delay: delayTime,
});

// Order Details
const OrderDetailsPage = Loadable({
  loader: () => import("./pages/OrdersList/OrderDetailsPage"),
  loading: PageLoader,
  delay: delayTime,
});

// Transaction List
const TransactionListPage = Loadable({
  loader: () => import("./pages/Transactions/TransactionListPage"),
  loading: PageLoader,
  delay: delayTime,
});

// Transaction Details
const TransactionDetailsPage = Loadable({
  loader: () => import("./pages/Transactions/TransactionDetailsPage"),
  loading: PageLoader,
  delay: delayTime,
});

// Business Settings
const Invoice = Loadable({
  loader: () => import("./pages/Invoice/Invoice.js"),
  loading: PageLoader,
  delay: delayTime,
});

// Stripe Payouts
const Payouts = Loadable({
  loader: () => import("./pages/Payouts/Payouts"),
  loading: PageLoader,
  delay: delayTime,
});

// Stripe Payouts
const DownloadsPage = Loadable({
  loader: () => import("./pages/DownloadsPage/DownloadsPage"),
  loading: PageLoader,
  delay: delayTime,
});

const CataloguePage = Loadable({
  loader: () => import("./pages/ProductsList"),
  loading: PageLoader,
  delay: delayTime,
});

// Business Settings
const BusinessSettings = Loadable({
  loader: () => import("./pages/BusinessSettings"),
  loading: PageLoader,
  delay: delayTime,
});

// Users
const UsersPage = Loadable({
  loader: () => import("./pages/Users.js"),
  loading: PageLoader,
  delay: delayTime,
});

// Products
const ProductsPage = Loadable({
  loader: () => import("./pages/ProductsList"),
  loading: PageLoader,
  delay: delayTime,
});

// Product Detail
const ProductDetailPage = Loadable({
  loader: () => import("./pages/ProductDetails"),
  loading: PageLoader,
  delay: delayTime,
});

// Add Edit Product
const AddEditProductPage = Loadable({
  loader: () => import("./pages/AddEditProduct"),
  loading: PageLoader,
  delay: delayTime,
});

// Collection Slots
const CollectionSlotPage = Loadable({
  loader: () => import("./pages/CollectionSlots"),
  loading: PageLoader,
  delay: delayTime,
});

// Feedback Page
const ReviewAndFeedbackPage = Loadable({
  loader: () => import("./pages/ReviewAndFeedback"),
  loading: PageLoader,
  delay: delayTime,
});

// Feedback detail Page
const FeedbackDetailPage = Loadable({
  loader: () => import("./pages/FeedbackDetailPage"),
  loading: PageLoader,
  delay: delayTime,
});

// Delivery Policy Page
const DeliveryPolicyPage = Loadable({
  loader: () => import("./pages/DeliveryPolicy"),
  loading: PageLoader,
  delay: delayTime,
});

// Bakery Settings
const BakerySettingsPage = Loadable({
  loader: () => import("./pages/BakerySettings"),
  loading: PageLoader,
  delay: delayTime,
});

// Category Listing
const CategoryListing = Loadable({
  loader: () => import("./pages/CategoryList/CategoryList"),
  loading: PageLoader,
  delay: delayTime,
});

// Category Details
const CategoryDetails = Loadable({
  loader: () => import("./pages/CategoryDetails"),
  loading: PageLoader,
  delay: delayTime,
});

// Add Edit Category
const AddEditCategoryPage = Loadable({
  loader: () => import("./pages/AddEditCategory"),
  loading: PageLoader,
  delay: delayTime,
});

// Modifier Listing
const ModifiersListing = Loadable({
  loader: () => import("./pages/ModifierList"),
  loading: PageLoader,
  delay: delayTime,
});

// Product Option Groups
const ProductOptionGroupPage = Loadable({
  loader: () => import("./pages/ProductOptionGroupsList"),
  loading: PageLoader,
  delay: delayTime,
});

// Modifier Details
const ModifierDetails = Loadable({
  loader: () => import("./pages/ModifierSetDetails"),
  loading: PageLoader,
  delay: delayTime,
});

// Product Option Group Detail
const ProductOptionGroupDetailPage = Loadable({
  loader: () => import("./pages/ProductOptionGroupDetail"),
  loading: PageLoader,
  delay: delayTime,
});

// Add Edit Modifier
const AddEditModifierPage = Loadable({
  loader: () => import("./pages/AddEditModifierSet"),
  loading: PageLoader,
  delay: delayTime,
});

// Product Option Group Add
const ProductOptionGroupAddPage = Loadable({
  loader: () => import("./pages/AddEditProductOptionGroup"),
  loading: PageLoader,
  delay: delayTime,
});

// Product Option Group Edit
const ProductOptionGroupEditPage = Loadable({
  loader: () => import("./pages/AddEditProductOptionGroup"),
  loading: PageLoader,
  delay: delayTime,
});

// Options Listing
const OptionsPage = Loadable({
  loader: () => import("./pages/OptionsList"),
  loading: PageLoader,
  delay: delayTime,
});

// Option Details
const OptionDetailsPage = Loadable({
  loader: () => import("./pages/OptionDetails"),
  loading: PageLoader,
  delay: delayTime,
});

// Option Add Edit
const OptionAddEdit = Loadable({
  loader: () => import("./pages/AddEditOption"),
  loading: PageLoader,
  delay: delayTime,
});

// Bakeries Listing
const BakeryListPage = Loadable({
  loader: () => import("./pages/BakeryList"),
  loading: PageLoader,
  delay: delayTime,
});

// Bakery Details
const BakeryDetailsPage = Loadable({
  loader: () => import("./pages/BakeryDetails"),
  loading: PageLoader,
  delay: delayTime,
});

// Partner Add Edit
const PartnerAddEdit = Loadable({
  loader: () => import("./pages/AddEditBakery"),
  loading: PageLoader,
  delay: delayTime,
});

// Promocodes
const PromocodesPage = Loadable({
  loader: () => import("./pages/Promocodes/Promocodes"),
  loading: PageLoader,
  delay: delayTime,
});

// Add Promocode
const AddPromoPage = Loadable({
  loader: () => import("./pages/AddPromocode/AddPromocode"),
  loading: PageLoader,
  delay: delayTime,
});

// Add Promocode
const EditPromoPage = Loadable({
  loader: () => import("./pages/EditPromocode/EditPromocode"),
  loading: PageLoader,
  delay: delayTime,
});

// Banners
const BannersPage = Loadable({
  loader: () => import("./pages/Customize/BannerList"),
  loading: PageLoader,
  delay: delayTime,
});

const BannerDetails = Loadable({
  loader: () => import("./pages/BannerDetails"),
  loading: PageLoader,
  delay: delayTime,
});

const EditBanner = Loadable({
  loader: () => import("./pages/EditBanner"),
  loading: PageLoader,
  delay: delayTime,
});

// Sections
const SectionsList = Loadable({
  loader: () => import("./pages/Customize/SectionList/SectionList"),
  loading: PageLoader,
  delay: delayTime,
});

// Popular Bakeries
const PopularBakeries = Loadable({
  loader: () => import("./pages/Customize/PopularBakeries/PopularBakeries"),
  loading: PageLoader,
  delay: delayTime,
});

// Loyalty Cards
const LoyaltyCards = Loadable({
  loader: () => import("./pages/LoyaltyCards/LoyaltyCards"),
  loading: PageLoader,
  delay: delayTime,
});

const AddLoyaltyCard = Loadable({
  loader: () => import("./pages/AddLoyaltyCard"),
  loading: PageLoader,
  delay: delayTime,
});

const EditLoyaltyCard = Loadable({
  loader: () => import("./pages/EditLoyaltyCard"),
  loading: PageLoader,
  delay: delayTime,
});

// 404
const Page404 = Loadable({
  loader: () => import("./pages/Page404"),
  loading: PageLoader,
  delay: delayTime,
});

const Routes = ({ childProps }) => {
  const props = {
    ...childProps,
    sessionCookie: userSessionCookie,
    redirectionRoute: getRedirectionRoute(),
  };

  return (
    <BrowserRouter basename="">
      <Switch>
        {/* Landing Page */}
        <AppliedRoute
          path={appRoutes.landingPage}
          exact
          component={LandingPage}
          props={props}
        />

        {/* TermsOfServiceAgreement */}
        <AppliedRoute
          path={appRoutes.termsOfServiceAgreement}
          exact
          component={TermsOfServiceAgreement}
          props={props}
        />

        {/* Login */}
        <AppliedRoute
          path={appRoutes.login}
          exact
          component={LoginPage}
          props={props}
        />

        {/* Signup */}
        <AppliedRoute
          path={appRoutes.signup}
          exact
          component={SignupPage}
          props={props}
        />

        {/* Forgot Password */}
        <AppliedRoute
          path={appRoutes.forgotPassword}
          exact
          component={ForgotPasswordPage}
          props={{ ...props }}
        />

        {/* Password Reset */}
        <AppliedRoute
          path={appRoutes.resetPassword}
          exact
          component={ResetPasswordPage}
          props={{ ...props }}
        />

        {/* Dashboard / Summary */}
        <AuthRoute
          path={appRoutes.dashboard}
          exact
          component={DashPage}
          props={{ ...props, permission: userPermissions.VIEW_SUMMARY }}
        />

        {/* Customers */}
        <AuthRoute
          path={appRoutes.accounts.list}
          exact
          component={CustomerListPage}
          props={{ ...props, permission: userPermissions.VIEW_SUMMARY }}
        />

        {/* Customer Details */}
        <AuthRoute
          path={appRoutes.accounts.details}
          exact
          component={CustomerDetailsPage}
          props={{ ...props, permission: userPermissions.VIEW_SUMMARY }}
        />

        {/* Orders */}
        <AuthRoute
          path={appRoutes.orders.list}
          exact
          component={OrdersListPage}
          props={{ ...props, permission: userPermissions.VIEW_ORDER }}
        />

        <AuthRoute
          path={appRoutes.orders.dispatchNote}
          exact
          component={Invoice}
          props={{
            ...props,
            permission: userPermissions.VIEW_ORDER,
          }}
          plainLayout
        />
        <AuthRoute
          path={appRoutes.orders.details}
          exact
          component={OrderDetailsPage}
          props={{
            ...props,
            permission: userPermissions.VIEW_ORDER,
          }}
        />

        {/* Transactions */}
        <AuthRoute
          path={appRoutes.transactions.list}
          exact
          component={TransactionListPage}
          props={{ ...props, permission: userPermissions.VIEW_TRANSACTION }}
        />

        <AuthRoute
          path={appRoutes.transactions.details}
          exact
          component={TransactionDetailsPage}
          props={{
            ...props,
            permission: userPermissions.VIEW_TRANSACTION,
          }}
        />

        {/* Downloads */}
        <AuthRoute
          path={appRoutes.downloads}
          exact
          component={DownloadsPage}
          props={{ ...props, permission: userPermissions.VIEW_DOWNLOADS }}
        />

        {/* Payments and Payouts */}
        <AuthRoute
          path={appRoutes.payouts}
          exact
          component={Payouts}
          props={{ ...props, permission: userPermissions.VIEW_PAYMENTS }}
        />

        {/* Business Settings */}
        <AuthRoute
          path={appRoutes.businessSettings}
          exact
          component={BusinessSettings}
          props={{ ...props, permission: userPermissions.VIEW_PAYMENTSETTINGS }}
          plainLayout
        />

        {/* Catalogue Page */}
        <AuthRoute
          path={appRoutes.cataloguePage}
          exact
          component={CataloguePage}
          props={{
            ...props,
            permission: userPermissions.VIEW_PRODUCT,
          }}
        />

        {/* Users */}
        <AuthRoute
          path={appRoutes.users}
          exact
          component={UsersPage}
          props={{ ...props, permission: userPermissions.ADD_USER }}
        />

        {/* Partners */}
        <AuthRoute
          path={appRoutes.partners.list}
          exact
          component={BakeryListPage}
          props={{ ...props, permission: userPermissions.VIEW_PARTNER }}
        />

        {/* Partner Details */}
        <AuthRoute
          path={appRoutes.partners.detail}
          exact
          component={BakeryDetailsPage}
          props={{ ...props, permission: userPermissions.VIEW_PARTNER }}
        />

        {/* Partner Add */}
        <AuthRoute
          path={appRoutes.partners.add}
          exact
          component={PartnerAddEdit}
          initialLoadState
          props={{
            ...props,
            page: PAGE.addBakery,
            permission: userPermissions.ADD_PARTNER,
          }}
        />

        {/* Partner Edit*/}
        <AuthRoute
          path={appRoutes.partners.edit}
          exact
          component={PartnerAddEdit}
          initialLoadState
          props={{
            ...props,
            page: PAGE.editBakery,
            permission: userPermissions.CHANGE_PARTNER,
          }}
        />

        {/* Products */}
        <AuthRoute
          path={appRoutes.cataloguePage.products.list}
          exact
          component={ProductsPage}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.products,
            permission: userPermissions.VIEW_PRODUCT,
          }}
        />

        {/* Add Product */}
        <AuthRoute
          path={appRoutes.cataloguePage.products.add}
          exact
          component={AddEditProductPage}
          initialLoadState
          props={{
            ...props,
            page: PAGE.addProduct,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.products,
            permission: userPermissions.ADD_PRODUCT,
          }}
        />

        {/* Product Detail */}
        <AuthRoute
          path={appRoutes.cataloguePage.products.detail}
          exact
          component={ProductDetailPage}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.products,
            permission: userPermissions.VIEW_PRODUCT,
          }}
        />

        {/* Product Edit */}
        <AuthRoute
          path={appRoutes.cataloguePage.products.edit}
          exact
          component={AddEditProductPage}
          initialLoadState
          props={{
            ...props,
            page: PAGE.editProduct,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.products,
            permission: userPermissions.CHANGE_PRODUCT,
          }}
        />

        {/* Collection Slot */}
        <AuthRoute
          path={appRoutes.collectionSlot}
          exact
          component={CollectionSlotPage}
          props={{ ...props, permission: userPermissions.VIEW_COLLECTIONSLOT }}
          plainLayout
        />

        {/* Feedback listing Page */}
        <AuthRoute
          path={appRoutes.userFeedback}
          exact
          component={ReviewAndFeedbackPage}
          props={{ ...props, permission: userPermissions.VIEW_USERFEEDBACK }}
          plainLayout
        />

        {/* Feedback Detail Page */}
        <AuthRoute
          path={appRoutes.feedbackDetail}
          exact
          component={FeedbackDetailPage}
          props={{ ...props, permission: userPermissions.VIEW_USERFEEDBACK }}
          plainLayout
        />

        {/* Delivery Policy */}
        <AuthRoute
          path={appRoutes.deliveryPolicy}
          exact
          component={DeliveryPolicyPage}
          props={{ ...props, permission: userPermissions.VIEW_DELIVERY_POLICY }}
          plainLayout
        />

        {/* Bakery Settings */}
        <AuthRoute
          path={appRoutes.bakerySettings}
          exact
          component={BakerySettingsPage}
          props={{ ...props, permission: userPermissions.CHANGE_PARTNER }}
          plainLayout
        />

        {/* Category List */}
        <AuthRoute
          path={appRoutes.cataloguePage.categories.list}
          exact
          component={CategoryListing}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.categories,
            permission: userPermissions.VIEW_CATEGORY,
          }}
        />

        {/* Add Category */}
        <AuthRoute
          path={appRoutes.cataloguePage.categories.add}
          exact
          component={AddEditCategoryPage}
          initialLoadState
          props={{
            ...props,
            page: PAGE.addCategory,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.categories,
            permission: userPermissions.ADD_CATEGORY,
          }}
        />

        {/* Category Details */}
        <AuthRoute
          path={appRoutes.cataloguePage.categories.detail}
          exact
          component={CategoryDetails}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.categories,
            permission: userPermissions.VIEW_CATEGORY,
          }}
        />

        {/* Edit Category */}
        <AuthRoute
          path={appRoutes.cataloguePage.categories.edit}
          exact
          component={AddEditCategoryPage}
          initialLoadState
          props={{
            ...props,
            page: PAGE.editCategory,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.categories,
            permission: userPermissions.CHANGE_CATEGORY,
          }}
        />

        {/* Modifier Set List */}
        <AuthRoute
          path={appRoutes.cataloguePage.modifiers.list}
          exact
          component={ModifiersListing}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.modifiers,
            permission: userPermissions.VIEW_MODIFIERS,
          }}
        />

        {/* Modifier Set Details */}
        <AuthRoute
          path={appRoutes.cataloguePage.modifiers.details}
          exact
          component={ModifierDetails}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.modifiers,
            permission: userPermissions.VIEW_MODIFIERS,
          }}
        />

        {/* Modifier Set Edit} */}
        <AuthRoute
          path={appRoutes.cataloguePage.modifiers.edit}
          exact
          component={AddEditModifierPage}
          initialLoadState
          props={{
            ...props,
            page: PAGE.editModifierSet,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.modifiers,
            permission: userPermissions.CHANGE_MODIFIERS,
          }}
        />

        {/* Modifier Set Add */}
        <AuthRoute
          path={appRoutes.cataloguePage.modifiers.add}
          exact
          component={AddEditModifierPage}
          initialLoadState
          props={{
            ...props,
            page: PAGE.addModifierSet,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.modifiers,
            permission: userPermissions.ADD_MODIFIERS,
          }}
        />

        {/* Product option groups */}
        <AuthRoute
          path={appRoutes.cataloguePage.productOptionGroups.list}
          exact
          component={ProductOptionGroupPage}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.optionGroups,
            permission: userPermissions.VIEW_OPTIONS,
          }}
        />

        {/* Add Product Option Group */}
        <AuthRoute
          path={appRoutes.cataloguePage.productOptionGroups.add}
          exact
          component={ProductOptionGroupAddPage}
          initialLoadState
          props={{
            ...props,
            page: PAGE.addOptionGroup,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.optionGroups,
            permission: userPermissions.ADD_OPTIONS,
          }}
        />

        {/* Product Option Group Detail */}
        <AuthRoute
          path={appRoutes.cataloguePage.productOptionGroups.detail}
          exact
          component={ProductOptionGroupDetailPage}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.optionGroups,
            permission: userPermissions.VIEW_OPTIONS,
          }}
        />

        {/* Edit Product Option Group */}
        <AuthRoute
          path={appRoutes.cataloguePage.productOptionGroups.edit}
          exact
          component={ProductOptionGroupEditPage}
          initialLoadState
          props={{
            ...props,
            page: PAGE.editOptionGroup,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.optionGroups,
            permission: userPermissions.CHANGE_OPTIONS,
          }}
        />

        {/* Personalization Listing */}
        <AuthRoute
          path={appRoutes.cataloguePage.options.list}
          exact
          component={OptionsPage}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.options,
            permission: userPermissions.VIEW_PERSONALIZATION,
          }}
        />

        {/* Add Personalization */}
        <AuthRoute
          path={appRoutes.cataloguePage.options.add}
          exact
          component={OptionAddEdit}
          initialLoadState
          props={{
            ...props,
            page: PAGE.addOption,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.option,
            permission: userPermissions.ADD_PERSONALIZATION,
          }}
        />

        {/* Personalization Details */}
        <AuthRoute
          path={appRoutes.cataloguePage.options.detail}
          exact
          component={OptionDetailsPage}
          props={{
            ...props,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.options,
            permission: userPermissions.VIEW_PERSONALIZATION,
          }}
        />

        {/* Edit Personalization */}
        <AuthRoute
          path={appRoutes.cataloguePage.options.edit}
          exact
          component={OptionAddEdit}
          initialLoadState
          props={{
            ...props,
            page: PAGE.editOption,
            activeItemId: CHILDREN_SIDENAV_CATALOUGUE_IDS.option,
            permission: userPermissions.CHANGE_PERSONALIZATION,
          }}
        />

        {/* Promocodes */}
        <AuthRoute
          path={appRoutes.promocodes.list}
          exact
          component={PromocodesPage}
          props={{ ...props, permission: userPermissions.VIEW_VOUCHER }}
        />

        {/* Add Promocode */}
        <AuthRoute
          path={appRoutes.promocodes.add}
          exact
          component={AddPromoPage}
          props={{ ...props, permission: userPermissions.ADD_VOUCHER }}
        />

        {/* Edit Promocode */}
        <AuthRoute
          path={appRoutes.promocodes.edit}
          exact
          component={EditPromoPage}
          props={{
            ...props,
            permission: userPermissions.CHANGE_VOUCHER,
          }}
        />

        {/* Loyalty Cards List */}
        <AuthRoute
          path={appRoutes.loyalty.list}
          exact
          component={LoyaltyCards}
          props={{
            ...props,
            permission: userPermissions.VIEW_LOYALTY_CARD,
            activeItemId: CHILDREN_LOYALTY_IDS.loyaltyCards,
          }}
        />

        {/* Add Loyalty Card */}
        <AuthRoute
          path={appRoutes.loyalty.add}
          exact
          component={AddLoyaltyCard}
          props={{
            ...props,
            permission: userPermissions.ADD_LOYALTY_CARD,
            activeItemId: CHILDREN_LOYALTY_IDS.loyaltyCards,
          }}
        />

        {/* Edit Loyalty Card */}
        <AuthRoute
          path={appRoutes.loyalty.edit}
          exact
          component={EditLoyaltyCard}
          props={{
            ...props,
            permission: userPermissions.CHANGE_LOYALTY_CARD,
            activeItemId: CHILDREN_LOYALTY_IDS.loyaltyCards,
          }}
        />

        {/* Site Configuration Pages */}
        <AuthRoute
          path={appRoutes.customize.banners}
          exact
          component={BannersPage}
          props={{
            ...props,
            permission: userPermissions.VIEW_BANNER,
            activeItemId: CHILDREN_SIDENAV_CUSTOMIZE_IDS.banners,
          }}
          plainLayout
        />

        {/* Banner Details */}
        <AuthRoute
          path={appRoutes.customize.detail}
          exact
          component={BannerDetails}
          props={{
            ...props,
            permission: userPermissions.VIEW_BANNER,
          }}
          plainLayout
        />

        {/* Add Banner */}
        <AuthRoute
          path={appRoutes.customize.add}
          exact
          component={EditBanner}
          props={{
            ...props,
            loadData: false,
            permission: userPermissions.ADD_BANNER,
          }}
          plainLayout
        />

        {/* Edit Banner */}
        <AuthRoute
          path={appRoutes.customize.edit}
          exact
          component={EditBanner}
          props={{
            ...props,
            permission: userPermissions.CHANGE_BANNER,
          }}
          plainLayout
        />

        {/* Sections List */}
        <AuthRoute
          path={appRoutes.customize.sectionList}
          exact
          component={SectionsList}
          props={{
            ...props,
            permission: userPermissions.VIEW_SECTION,
            activeItemId: CHILDREN_SIDENAV_CUSTOMIZE_IDS.sections,
          }}
          plainLayout
        />

        {/* Popular Bakeries */}
        <AuthRoute
          path={appRoutes.customize.popularBakeries}
          exact
          component={PopularBakeries}
          props={{
            ...props,
            permission: userPermissions.VIEW_POPULAR_BAKERIES,
            activeItemId: CHILDREN_SIDENAV_CUSTOMIZE_IDS.popularBakeries,
          }}
          plainLayout
        />

        {/* Finally, catch all unmatched routes */}
        <Route component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

Routes.propTypes = {
  childProps: PropTypes.element,
};

Routes.defaultProps = {
  childProps: <></>,
};

export default Routes;
