import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSessionStorageItem, setSessionStorageItem } from "utils/session";

import "./CustomLayout.scss";
import { handleError } from "utils/toast-handler";
import {
  getSession,
  deleteCookie,
  setSession as setCookieeSession,
} from "utils/auth";
import { appRoutes, userSessionCookie } from "utils/config";
import { apiRoutes } from "utils/api-routes";

import Select from "react-select";

const SuppliersList = () => {
  // States
  const [ssn, setSession] = useState({});
  const [activePartner, setActivePartner] = useState({});
  const [isMultiPartnerUser, setIsMultiPartnerUser] = useState(false);
  const [partnerOptions, setPartnerOptions] = useState([]);

  // Function - fetch bakeries
  const fetchBakeries = () => {
    if (!ssn || !ssn.userprofile) return;
    let bakeriesList = [];
    const route = apiRoutes.partners;
    window.axios
      .get(route)
      .then((response) => {
        if (response.data.length > 1) setIsMultiPartnerUser(true);
        if (ssn.userprofile?.is_superuser) {
          bakeriesList.push({
            label: "All",
            value: {
              name: "All",
              code: "all",
              postcode: "",
              delivery: false,
              collection: false,
              collection_slots: false,
              domain_name: "",
            },
          });
        }
        bakeriesList = bakeriesList.concat(
          response.data.map((partner) => ({
            label: partner.name,
            value: partner,
          }))
        );
        setPartnerOptions(bakeriesList);
      })
      .catch((err) => handleError(err));
  };

  // If session is updated then fetch bakeries
  useEffect(() => {
    fetchBakeries();
  }, [ssn]);

  // History
  const history = useHistory();

  // useEffect - get session information
  useEffect(() => {
    // If the cookie is not present then redirect to login
    const session = getSession(userSessionCookie);
    if (!session) {
      deleteCookie(userSessionCookie);
      history.push(appRoutes.login);
      return;
    }
    setSession(session);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set active partner on fetch of partners
  useEffect(() => {
    if (partnerOptions.length === 0) return;
    const previousPartnerCode = getSessionStorageItem("activePartner");
    if (previousPartnerCode) {
      const previousPartner = partnerOptions.find((partner) => {
        return partner.value.code === previousPartnerCode;
      });
      if (previousPartner) {
        setActivePartner(previousPartner);
        return;
      }
    }
    const defaultPartner = partnerOptions[0];
    handlePartnerChanged(defaultPartner);
    setSessionStorageItem("activePartner", defaultPartner.value.code);
  }, [partnerOptions]);

  function handlePartnerChanged(newActivePartner) {
    if (ssn && ssn.userprofile) {
      let tempPartner = {};
      if (newActivePartner.label === "All") {
        tempPartner["name"] = "All";
        tempPartner["store_code"] = "all";
        tempPartner["postcode"] = "";
        tempPartner["delivery"] = false;
        tempPartner["collection"] = false;
        tempPartner["collection_slots"] = false;
        tempPartner["domain_name"] = "";
        ssn.userprofile.partner = tempPartner;
      } else {
        tempPartner["name"] = newActivePartner.value["name"];
        tempPartner["store_code"] = newActivePartner.value["code"];
        tempPartner["postcode"] = newActivePartner.value["postcode"];
        tempPartner["delivery"] = newActivePartner.value["supports_delivery"];
        tempPartner["collection"] =
          newActivePartner.value["supports_collection"];
        tempPartner["collection_slots"] =
          newActivePartner.value["supports_collection_slots"];
        tempPartner["domain_name"] = newActivePartner.value["domain_name"];
        ssn.userprofile.partner = tempPartner;
      }
      setCookieeSession(ssn, userSessionCookie);
      setSessionStorageItem("activePartner", newActivePartner.value.code);
      setActivePartner(activePartner);
      window.location.reload();
    }
  }

  return (
    <div style={{ marginTop: "auto", marginBottom: "auto", width: "250px" }}>
      {partnerOptions.length > 0 && (
        <Select
          options={partnerOptions}
          onChange={handlePartnerChanged}
          value={activePartner}
          isSearchable={true}
          theme={(theme) => ({
            ...theme,
            borderRadius: "4px",
            colors: {
              ...theme.colors,
              primary: "white",
              primary25: "lightgrey",
              primary50: "grey",
              primary75: "darkgrey",
              danger: "red",
              dangerLight: "pink",
              neutral0: "darkgrey",
              neutral5: "black",
              neutral10: "black",
              neutral20: "black",
              neutral30: "black",
              neutral40: "black",
              neutral50: "black",
              neutral60: "black",
              neutral70: "black",
              neutral80: "black",
              neutral90: "black",
            },
          })}
          isDisabled={!isMultiPartnerUser}
          styles={{
            singleValue: ({
              maxWidth,
              position,
              top,
              transform,
              ...otherStyles
            }) => ({ ...otherStyles }),
          }}
        />
      )}
    </div>
  );
};

export default SuppliersList;
