const baseUrl = process.env.REACT_APP_API_URL;
const dashUrl = `${baseUrl}/api/dashboard`;
const apiRoutes = {
  // Auth
  login: `${dashUrl}/login/`,
  logout: `${dashUrl}/logout/`,

  // Signup
  signup: `${dashUrl}/signup/`,

  // Change Password
  changePassword: `${dashUrl}/password-change/`,

  // Forgot Password
  forgotPassword: `${dashUrl}/forgot-password/`,

  // Reset Pasword
  resetPasswordTokenVerification: `${dashUrl}/password-reset-token/`,
  resetPassword: `${dashUrl}/password-reset/`,

  // Analytics
  stats: (storeCode) => `${dashUrl}/stats/${storeCode}/`,

  // Users
  addUser: `${dashUrl}/create-user/`,

  // Partners
  addPartner: `${baseUrl}/stores/onboard/`,
  addDeliveryPolicy: `${dashUrl}/stores/delivery-policy/add/`,
  partners: `${dashUrl}/partners/`,
  partnerDetails: `${dashUrl}/partners/:code/`,
  addPartnerUser: `${dashUrl}/partners/create-user/`,
  users: `${dashUrl}/partners/users/`,
  payoutDetails: `${dashUrl}/partners/:storeCode/stripe-details/`,
  bankDetails: `${dashUrl}/partners/:storeCode/bank-details/`,
  payments: (storeCode) => `${dashUrl}/partners/transfers/${storeCode}/`,
  partnerPayouts: (storeCode) => `${dashUrl}/partners/payouts/${storeCode}/`,
  fetchPDF: (storeCode) => `${dashUrl}/partners/${storeCode}/generate-pdf/`,
  payoutPaymentBalance: (storeCode) =>
    `${dashUrl}/partners/${storeCode}/balance/`,
  payoutScheduleUpdate: (storeCode) =>
    `${dashUrl}/partners/${storeCode}/payouts/schedule/`,
  partnersTaxInformation: (storeCode) =>
    `${dashUrl}/partners/${storeCode}/tax-information/`,
  collectionSlot: `${dashUrl}/partners/:storeCode/collection-slots/`,
  collectionSlotUpdate: `${dashUrl}/partners/:storeCode/collection-slots/update/`,
  reviewsAndFeedback: `${dashUrl}/partners/:storeCode/reviews-and-feedback/`,

  // Catalogue
  products: `${dashUrl}/catalogue/:storeCode/`,
  productDetail: `${dashUrl}/catalogue/:storeCode/:uniqueSlug/`,
  categories: `${dashUrl}/catalogue/:storeCode/categories/`,
  productAttributes: `${dashUrl}/catalogue/product-attributes/`,
  partnerBusinessDetails: `${dashUrl}/partners/:storeCode/details/`,
  categoryDetail: `${dashUrl}/catalogue/:storeCode/category/:uniqueSlug/`,
  categoryDelete: `${dashUrl}/catalogue/:storeCode/category/:uniqueSlug/delete/`,
  catergoryReorder: `${dashUrl}/catalogue/:storeCode/categories/reorder/`,
  createCatergory: `${dashUrl}/catalogue/:storeCode/categories/create/`,
  modifiers: `${dashUrl}/catalogue/:storeCode/modifier-set/`,
  modifierSetDetail: `${dashUrl}/catalogue/:storeCode/modifier-set/:modifierSetId/`,
  productOptionGroups: `${dashUrl}/catalogue/:storeCode/option-group/`,
  productOptionGroupDeatil: `${dashUrl}/catalogue/:storeCode/option-group/:groupId/`,
  tags: `${dashUrl}/catalogue/tags/`,
  productOptions: `${dashUrl}/catalogue/:storeCode/product-option/:uniqueSlug/`,
  productModifiers: `${dashUrl}/catalogue/:storeCode/product-modifier/:uniqueSlug/`,
  options: `${dashUrl}/catalogue/:storeCode/option/`,
  optionsDetail: `${dashUrl}/catalogue/:storeCode/option/:optionId/`,
  productAvailability: `${dashUrl}/catalogue/:storeCode/product-availability/:uniqueSlug/`,

  // Orders
  orders: `${dashUrl}/orders/`,
  dispatchNote: `/orders/dispatch-note/`,
  orderPipeline: `${dashUrl}/orders/pipeline/`,
  orderDetails: (storeCode, orderId) =>
    `${dashUrl}/orders/${storeCode}/${orderId}/`,
  orderStatus: `${dashUrl}/orders/:orderId/status/`,
  orderProof: `${dashUrl}/orders/:orderId/proof/`,
  orderCount: (partnerCode) => `${dashUrl}/orders/count/${partnerCode}/`,
  customers: (storeCode) => `${dashUrl}/orders/${storeCode}/customer-list/`,
  ordersCsv: `/orders/orders-csv/`,
  bulkUpdateOrderStatuses: `/orders/update-order-statuses/`,

  // Testimonial
  testimonial: `${baseUrl}/testimony/list/`,

  // Subscribe to newsletter
  subscribe: `${baseUrl}/accounts/subscribe/`,

  // Shipping
  addTrackingDetail: `${dashUrl}/orders/tracking-details/`,
  editTrackingDetail: `${dashUrl}/orders/tracking-details/:orderId/`,
  trackingURLs: `${dashUrl}/orders/tracking-urls/`,

  // Promocodes
  promocodesList: `${dashUrl}/vouchers/`,
  promocodeDetails: (id) => `${dashUrl}/vouchers/voucher/${id}/`,
  updatePromocode: (id) => `${dashUrl}/vouchers/update-voucher/${id}/`,
  conditionsList: `${dashUrl}/vouchers/conditions/`,
  benefitsList: `${dashUrl}/vouchers/benefits/`,
  loyaltyCardsList: `${dashUrl}/vouchers/loyalty-cards/`,
  loyaltyCardDetails: (id) => `${dashUrl}/vouchers/loyalty-card/${id}/`,
  updateLoyaltyCard: (id) => `${dashUrl}/vouchers/update-loyalty-card/${id}/`,

  // Customization
  bannersList: `${dashUrl}/list-banners/`,
  bannerDetails: (id) => `${dashUrl}/banner-details/${id}/`,
  sectionsList: `${dashUrl}/section-list/`,
  updateSection: (id) => `${dashUrl}/update-section/${id}/`,
  popularBakeries: `${dashUrl}/popular-bakeries/`,
  updatePopularBakery: (id) => `${dashUrl}/update-popular-bakery/${id}/`,

  // Transactions
  transactions: `${dashUrl}/partners/transactions/`,
  transactionDetail: (transactionId) =>
    `${dashUrl}/partners/transactions/${transactionId}/`,
};

export { apiRoutes, dashUrl };
