import {
  faChartPie,
  faClipboardList,
  faCreditCard,
  // faListAlt,
  // faReceipt,
  // faDownload,
  faUser,
  // faStoreAlt,
  // faTrophy,
} from "@fortawesome/free-solid-svg-icons";

import { userPermissions, isUserAllowed } from "./permissions";
import { getSession } from "./auth";

import { include } from "named-urls";

const SIDENAV_IDS = {
  summary: "summary",
  orders: "orders",
  payouts: "payouts",
  catalogue: "catalogue",
  downloads: "downloads",
  paymentSettings: "business-settings",
  collectionSlots: "collection-slots",
  deliveryPolicy: "delivery-policy",
  preference: "bakery-preferences",
  bakeries: "partners",
  promocodes: "vouchers",
  customize: "customize",
  reviewsAndFeedback: "reviews-and-feedback",
  loyalty: "loyalty",
  accounts: "accounts",
  users: "users",
  transactions: "transactions",
};

const COMPONENT_IDS = {
  first_section: "first_section",
  learnmore: "learnmore",
};

const BAKERS_MARKET_URL = {
  home: "",
  privacy_policy: "/privacy-policy",
  about: "/about",
  cookies_page: "/cookies-page",
  terms_of_service_agreement: "/terms-of-service-agreement",
  help_centre: "/help-centre",
  acceptable_use_policy: "/acceptable-use-policy",
};

const appRoutes = {
  // Auth
  login: "/login",

  // Sign up
  signup: "/signup",

  // Forgot Password
  forgotPassword: "/forgot-password",

  // Reset Password
  resetPassword: "/reset-password/:token",

  // Orders
  orders: include(`/${SIDENAV_IDS.orders}`, {
    list: "",
    dispatchNote: ":orderId/dispatch-note/",
    details: ":storeCode/:orderId",
  }),

  // Customers
  accounts: include(`/${SIDENAV_IDS.accounts}`, {
    list: "",
    details: ":customerId",
  }),

  // Transactions
  transactions: include(`/${SIDENAV_IDS.transactions}`, {
    list: "",
    details: ":transactionId",
  }),

  // Payouts
  payouts: `/${SIDENAV_IDS.payouts}`,

  // Dashboard or Summary
  dashboard: `/${SIDENAV_IDS.summary}`,

  // Downloads
  downloads: `/${SIDENAV_IDS.downloads}`,

  // Business settings
  businessSettings: `/${SIDENAV_IDS.paymentSettings}`,

  // Users
  users: "/users/create",

  // Partners
  partners: include(`/${SIDENAV_IDS.bakeries}`, {
    list: "",
    detail: ":code/details",
    edit: ":code/edit",
    add: "add/",
  }),

  loyalty: include(`/${SIDENAV_IDS.loyalty}`, {
    list: "",
    add: "add-loyalty-card/",
    edit: "edit-loyalty-card/:loyaltyCardId",
  }),

  cataloguePage: include(`/${SIDENAV_IDS.catalogue}`, {
    // Products
    products: include("products/", {
      list: "",
      detail: ":uniqueSlug/details",
      edit: ":uniqueSlug/edit",
      add: "add/",
    }),

    // Categories
    categories: include("categories/", {
      list: "",
      detail: ":uniqueSlug/details",
      edit: ":uniqueSlug/edit",
      add: "add/",
    }),

    // Modifiers
    modifiers: include("modifiers/", {
      list: "",
      details: ":modifierSetId/details",
      edit: ":modifierSetId/edit",
      add: "add/",
    }),

    // Product Option Groups
    productOptionGroups: include("option-groups/", {
      list: "",
      detail: ":groupId/details",
      edit: ":groupId/edit",
      add: "add/",
    }),

    // Options
    options: include("options/", {
      list: "",
      detail: ":optionId/details",
      edit: ":optionId/edit",
      add: "add/",
    }),
  }),

  customize: include(`/${SIDENAV_IDS.customize}/`, {
    banners: "banners/",
    detail: "banner/:bannerId",
    add: "add-banner/",
    edit: "edit-banner/:bannerId",
    sectionList: "sections/",
    popularBakeries: "popular-bakeries/",
  }),

  // Promocodes
  promocodes: include(`/${SIDENAV_IDS.promocodes}`, {
    list: "",
    detail: "voucher-details/:promocode/",
    add: "add-voucher/",
    edit: "edit-promocode/:promocode/",
  }),

  // Collection Slot
  collectionSlot: `/${SIDENAV_IDS.collectionSlots}`,

  // User feedback
  userFeedback: `/${SIDENAV_IDS.reviewsAndFeedback}`,

  feedbackDetail: `/${SIDENAV_IDS.reviewsAndFeedback}/view/:feedbackId/`,

  deliveryPolicy: `/${SIDENAV_IDS.deliveryPolicy}`,

  // Bakery Settings
  bakerySettings: `/${SIDENAV_IDS.preference}`,

  // Landing Page
  landingPage: "/",

  // Terms of service agreement
  termsOfServiceAgreement: "/terms-for-sellers",
};

// Items for Children sidenav inside Fixed Layout

// Catalogue Children Sidenav
const CHILDREN_SIDENAV_CATALOUGUE_IDS = {
  products: "products",
  categories: "categories",
  modifiers: "modifiers",
  optionGroups: "option-groups",
  options: "options",
};

const CHILDREN_SIDENAV_CUSTOMIZE_IDS = {
  banners: "banners",
  sections: "sections",
  popularBakeries: "popularBakeries",
};

const CHILDREN_LOYALTY_IDS = {
  loyaltyCards: "loyalty-cards",
};

const PAGE = {
  editProduct: "editProduct",
  addProduct: "addProduct",
  editCategory: "editCategory",
  addCategory: "addCategory",
  addModifierSet: "addModifierSet",
  editModifierSet: "editModifierSet",
  editOptionGroup: "editOptionGroup",
  addOptionGroup: "addOptionGroup",
  editOption: "editOption",
  addOption: "addOption",
  addBakery: "addBakery",
  editBakery: "editBakery",
};

const userSessionCookie = "bako";

const APP_VERSION = "1.0.0";

const SIDENAV_ITEMS = [
  {
    position: 1, // position is given for reference only
    displayName: "Summary",
    route: appRoutes.dashboard,
    id: SIDENAV_IDS.summary,
    permissions: [userPermissions.VIEW_SUMMARY],
    icon: faChartPie,
  },
  {
    position: 2,
    displayName: "Orders",
    route: appRoutes.orders.list,
    id: SIDENAV_IDS.orders,
    permissions: [userPermissions.VIEW_ORDER],
    icon: faClipboardList,
  },
  {
    position: 3,
    displayName: "Transactions",
    route: appRoutes.transactions.list,
    id: SIDENAV_IDS.transactions,
    permissions: [userPermissions.VIEW_SUMMARY],
    icon: faCreditCard,
  },
  // {
  //   position: 3,
  //   displayName: "Payments & Payouts",
  //   route: appRoutes.payouts,
  //   id: SIDENAV_IDS.payouts,
  //   permissions: [userPermissions.VIEW_PAYMENTS],
  //   icon: faCreditCard,
  // },
  // {
  //   position: 4,
  //   displayName: "Catalogue",
  //   route: appRoutes.cataloguePage.products.list,
  //   id: SIDENAV_IDS.catalogue,
  //   icon: faListAlt,
  //   children: [
  //     {
  //       title: "Products",
  //       route: appRoutes.cataloguePage.products.list,
  //       id: CHILDREN_SIDENAV_CATALOUGUE_IDS.products,
  //       permissions: [userPermissions.VIEW_PRODUCT],
  //     },
  //     {
  //       title: "Categories",
  //       route: appRoutes.cataloguePage.categories.list,
  //       id: CHILDREN_SIDENAV_CATALOUGUE_IDS.categories,
  //       permissions: [userPermissions.VIEW_CATEGORY],
  //     },
  //     {
  //       title: "Modifiers",
  //       route: appRoutes.cataloguePage.modifiers.list,
  //       id: CHILDREN_SIDENAV_CATALOUGUE_IDS.modifiers,
  //       permissions: [userPermissions.VIEW_MODIFIERS],
  //     },
  //     {
  //       title: "Options",
  //       route: appRoutes.cataloguePage.productOptionGroups.list,
  //       id: CHILDREN_SIDENAV_CATALOUGUE_IDS.optionGroups,
  //       permissions: [userPermissions.VIEW_OPTIONS],
  //     },
  //     {
  //       title: "Personalizations",
  //       route: appRoutes.cataloguePage.options.list,
  //       id: CHILDREN_SIDENAV_CATALOUGUE_IDS.options,
  //       permissions: [userPermissions.VIEW_PERSONALIZATION],
  //     },
  //   ],
  //   permissions: [
  //     userPermissions.VIEW_PRODUCT,
  //     userPermissions.VIEW_CATEGORY,
  //     userPermissions.VIEW_MODIFIERS,
  //     userPermissions.VIEW_OPTIONS,
  //     userPermissions.VIEW_PERSONALIZATION,
  //   ],
  // },
  // {
  //   position: 5,
  //   displayName: "Vouchers",
  //   route: appRoutes.promocodes.list,
  //   id: SIDENAV_IDS.promocodes,
  //   permissions: [userPermissions.VIEW_VOUCHER],
  //   icon: faReceipt,
  // },
  // {
  //   position: 6,
  //   displayName: "Downloads",
  //   route: appRoutes.downloads,
  //   id: SIDENAV_IDS.downloads,
  //   permissions: [userPermissions.VIEW_DOWNLOADS],
  //   icon: faDownload,
  // },
  // {
  //   position: 7,
  //   displayName: "Users",
  //   route: appRoutes.users,
  //   id: "users",
  //   permissions: [userPermissions.ADD_USER],
  //   icon: faUser,
  // },
  // {
  //   position: 8,
  //   displayName: "Partners",
  //   route: appRoutes.partners.list,
  //   id: SIDENAV_IDS.partners,
  //   permissions: [userPermissions.VIEW_PARTNER],
  //   icon: faStoreAlt,
  // },
  // {
  //   position: 9,
  //   displayName: "Loyalty",
  //   route: appRoutes.loyalty.list,
  //   id: SIDENAV_IDS.loyalty,
  //   children: [
  //     {
  //       title: "Loyalty Cards",
  //       route: appRoutes.loyalty.list,
  //       id: CHILDREN_LOYALTY_IDS.loyaltyCards,
  //       permissions: [userPermissions.VIEW_LOYALTY_CARD],
  //     },
  //   ],
  //   permissions: [userPermissions.VIEW_LOYALTY_CARD],
  //   icon: faTrophy,
  // },
  {
    position: 10,
    displayName: "Accounts",
    route: appRoutes.accounts.list,
    id: SIDENAV_IDS.accounts,
    permissions: [
      userPermissions.VIEW_CUSTOMER_PERM,
      userPermissions.VIEW_CUSTOMER_DETAIL_PERM,
    ],
    icon: faUser,
  },
];

const getRedirectionRoute = (sessionData) => {
  let redirectUrl = appRoutes.dashboard;
  const session = sessionData || getSession(userSessionCookie);
  if (!session || !session.permissions || !session.permissions.length) {
    return redirectUrl;
  }
  SIDENAV_ITEMS.every((item) => {
    let isPermitted = false;
    if (!item.children) {
      isPermitted = isUserAllowed(item, session);
      if (isPermitted) {
        redirectUrl = item.route;
      }
    } else {
      item.children.every((childItem) => {
        isPermitted = isUserAllowed(childItem, session);
        if (isPermitted) {
          redirectUrl = childItem.route;
        }
        return !isPermitted;
      });
    }
    return !isPermitted;
  });
  return redirectUrl;
};

export {
  appRoutes,
  userSessionCookie,
  SIDENAV_ITEMS,
  APP_VERSION,
  PAGE,
  CHILDREN_SIDENAV_CATALOUGUE_IDS,
  CHILDREN_SIDENAV_CUSTOMIZE_IDS,
  CHILDREN_LOYALTY_IDS,
  COMPONENT_IDS,
  BAKERS_MARKET_URL,
  SIDENAV_IDS,
  getRedirectionRoute,
};
