import React from "react";
import { useHistory } from "react-router-dom";

import { logout } from "../utils/auth";
import { setSessionStorageItem } from "utils/session";

const Logout = () => {
  const history = useHistory();

  const onLogout = () => {
    logout(history);
    setSessionStorageItem("activePartner", false);
  };

  return (
    <div className="logout" role="button" onClick={onLogout}>
      Logout
    </div>
  );
};

export default Logout;
