import { getSession } from "./auth";
import { userSessionCookie } from "./config";

const userPermissions = {
  CHANGE_ORDER: "order.change_order",
  VIEW_ORDER: "order.view_order",
  VIEW_PRODUCT: "catalogue.view_product",
  CHANGE_PRODUCT: "catalogue.change_product",
  ADD_PRODUCT: "catalogue.add_product",
  DELETE_PRODUCT: "catalogue.delete_product",
  VIEW_CATEGORY: "catalogue.view_category",
  CHANGE_CATEGORY: "catalogue.change_category",
  ADD_CATEGORY: "catalogue.add_category",
  DELETE_CATEGORY: "catalogue.delete_category",
  VIEW_MODIFIERS: "catalogue.view_modifierset",
  CHANGE_MODIFIERS: "catalogue.change_modifierset",
  ADD_MODIFIERS: "catalogue.add_modifierset",
  DELETE_MODIFIERS: "catalogue.delete_modifierset",
  VIEW_PERSONALIZATION: "catalogue.view_option",
  CHANGE_PERSONALIZATION: "catalogue.change_option",
  ADD_PERSONALIZATION: "catalogue.add_option",
  DELETE_PERSONALIZATION: "catalogue.delete_option",
  VIEW_OPTIONS: "catalogue.view_attributeoptiongroup",
  CHANGE_OPTIONS: "catalogue.change_attributeoptiongroup",
  ADD_OPTIONS: "catalogue.add_attributeoptiongroup",
  DELETE_OPTIONS: "catalogue.delete_attributeoptiongroup",
  VIEW_SUMMARY: "order.view_summary",
  VIEW_PAYMENTS: "order.view_payments",
  VIEW_DOWNLOADS: "partner.view_downloads",
  VIEW_PAYMENTSETTINGS: "partner.view_payment_settings",
  CHANGE_PAYMENTSETTINGS: "partner.change_payment_settings",
  VIEW_COLLECTIONSLOT: "partner.view_collectionslot",
  CHANGE_COLLECTIONSLOT: "partner.change_collectionslot",
  DELETE_COLLECTIONSLOT: "partner.delete_collectionslot",
  ADD_COLLECTIONSLOT: "partner.add_collectionslot",
  ADD_USER: "customer.add_userprofile",
  VIEW_PARTNER: "partner.view_partner",
  ADD_PARTNER: "partner.add_partner",
  CHANGE_PARTNER: "partner.change_partner",
  VIEW_VOUCHER: "voucher.view_voucher",
  ADD_VOUCHER: "voucher.add_voucher",
  DELETE_VOUCHER: "voucher.delete_voucher",
  CHANGE_VOUCHER: "voucher.change_voucher",
  VIEW_BANNER: "pages.view_bannerimage",
  ADD_BANNER: "pages.add_bannerimage",
  CHANGE_BANNER: "pages.change_bannerimage",
  VIEW_SECTION: "catalogue.view_section",
  ADD_SECTION: "catalogue.add_section",
  CHANGE_SECTION: "catalogue.change_section",
  VIEW_POPULAR_BAKERIES: "catalogue.view_section",
  ADD_POPULAR_BAKERIES: "catalogue.add_section",
  CHANGE_POPULAR_BAKERIES: "catalogue.change_section",
  VIEW_USERFEEDBACK: "customer.view_userfeedback",
  VIEW_LOYALTY_CARD: "voucher.view_loyaltycard",
  ADD_LOYALTY_CARD: "voucher.add_loyaltycard",
  CHANGE_LOYALTY_CARD: "voucher.change_loyaltycard",
  VIEW_DELIVERY_POLICY: "pages.view_page",
  ADD_USER_CODE: "customer.add_userprofile",
  VIEW_USERFEEDBACK_CODE: "customer.view_userfeedback",
  VIEW_CUSTOMER_PERM: "customer.view_customer",
  VIEW_CUSTOMER_DETAIL_PERM: "customer.view_customer_detail",
  APPROVE_CUSTOMER_TAX_CERTIFICATE_PERM:
    "customer.approve_customer_tax_certificate",
  VIEW_TRANSACTION: "partner.view_transaction",
  ADD_TRANSACTION: "partner.add_transaction",
  UPDATE_TRANSACTION: "partner.change_transaction",
};

const evaluatePermission = (permission, sessionData) => {
  const session = sessionData || getSession(userSessionCookie);
  if (!session || !session.permissions || !session.permissions.length) {
    return false;
  }
  return session.permissions.includes(permission);
};

const isUserAllowed = (navItem, session) => {
  const permissions = navItem.permissions;
  let allowed = !permissions || !permissions.length;
  if (!allowed) {
    permissions.every((permission) => {
      allowed = evaluatePermission(permission, session);
      return !allowed;
    });
  }
  return allowed;
};

export { userPermissions, evaluatePermission, isUserAllowed };
