import { userSessionCookie, appRoutes } from "./config";
import { apiRoutes } from "./api-routes";

const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
};

const getSession = (cookieName) => {
  let sessionData;
  try {
    sessionData = JSON.parse(getCookie(cookieName)) || {};
  } catch (e) {
    sessionData = {};
    setSession(sessionData); // set it empty
  }
  return sessionData;
};

const getStoreCode = (session) => {
  const data = session || getSession(userSessionCookie);
  return (
    data.userprofile &&
    data.userprofile.partner &&
    data.userprofile.partner.store_code
  );
};

const logout = (history) => {
  window.axios.delete(apiRoutes.logout);
  deleteCookie(userSessionCookie);
  history.push(appRoutes.login);
};

const logoutWithNext = () => {
  window.axios.delete(apiRoutes.logout);
  deleteCookie(userSessionCookie);
  let loginUrl = appRoutes.login;
  const currentPath = window.location.pathname;
  if (currentPath !== appRoutes.login && currentPath !== appRoutes.dashboard) {
    loginUrl = `${appRoutes.login}?next=${appRoutes.dashboard}`;
  }
  window.location.assign(loginUrl);
};

const setSession = (data, cookieName) => {
  setCookie(cookieName, JSON.stringify(data), 1);
};

const updateSession = (data) => {
  const currentSessionData = getSession(userSessionCookie);
  if (data && currentSessionData) {
    currentSessionData.first_name = data.first_name;
    currentSessionData.last_name = data.last_name;
    currentSessionData.color = data.organisation.color;
    currentSessionData.logo = data.organisation.logo;
    currentSessionData.permissions = data.permissions;
    currentSessionData.organisation_admin = data.organisation_admin;
    setSession(currentSessionData, userSessionCookie);
  }
};

const isUserLoggedIn = (session) => {
  let userLoggedIn = false;
  const sessionData = session || getSession(userSessionCookie);
  if (
    sessionData &&
    Object.entries(sessionData).length > 0 &&
    sessionData.key
  ) {
    userLoggedIn = true;
  }

  return userLoggedIn;
};

export {
  getCookie,
  setCookie,
  deleteCookie,
  getSession,
  setSession,
  updateSession,
  isUserLoggedIn,
  logout,
  getStoreCode,
  logoutWithNext,
};
