import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Navbar, Nav, Button, Dropdown, Container } from "react-bootstrap";
import ChangePassword from "../ChangePassword";
import { getSession, deleteCookie } from "../../utils/auth";
import { getBakeryName, getPartner } from "../../utils/common";
import { appRoutes, userSessionCookie } from "../../utils/config";
import GetAllBakeries from "../CustomLayout/GetAllBakeries";
import {
  faExternalLinkAlt,
  faUserAlt,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logout from "../Logout";
import "./NavbarHamburger.scss";
import CustomToggle from "components/ProductFilter/CustomToggle";
import PropTypes from "prop-types";
import SuppliersList from "components/CustomLayout/SuppliersList";

const NavbarHamburger = (props) => {
  const history = useHistory();

  // States
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [ssn, setSession] = useState({});
  const [activeParnterName, setActivePartnerName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const bakerySiteRedirectIcon = (
    <FontAwesomeIcon icon={faExternalLinkAlt} size="sm" className="mx-1" />
  );

  // useEffect - get session information
  useEffect(() => {
    // If the cookie is not present then redirect to login
    const session = getSession(userSessionCookie);
    if (!session) {
      deleteCookie(userSessionCookie);
      history.push(appRoutes.login);
      return;
    }
    setSession(session);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    // Get active barkery name
    const partner = getPartner(ssn);
    const bakeryName = getBakeryName(partner);
    setActivePartnerName(bakeryName);
  }, [ssn]);

  // Function - get bakery site
  const getBakerySiteLink = () => {
    const partner = getPartner(ssn);
    const storeCode = partner && partner.domain_name;
    let link = "/";

    if (storeCode) {
      link = `https://${storeCode}.bakersmarket.co.uk`;
      const domain = window.location.hostname;
      if (domain.includes("bm.vidatech.uk")) {
        link = `https://${storeCode}.bm.vidatech.uk`;
      }
    }
    return link;
  };
  const hamburgerIcon = <FontAwesomeIcon icon={faBars} size="lg" />;

  return (
    <div className="navbar-hamburger">
      <Navbar expand="lg" bg="dark" fixed="top" className="border-bottom px-5">
        <Container fluid>
          <Nav className="me-2 d-lg-none">
            <Button className="hamburger-button" onClick={props.toggleMenu}>
              {hamburgerIcon}
            </Button>
          </Nav>
          <img
            alt="logo"
            className="brand-logo"
            src={"/svg/ynot-logo-white.svg"}
            height="60"
          />
          <div
            className="d-flex justify-content-start align-items-baseline"
            style={{ color: "#00B5E2", marginLeft: "20px" }}
          >
            {ssn.userprofile?.is_superuser ? (
              <h1 id="adminDashboardNavbarHeading">ADMIN DASHBOARD</h1>
            ) : (
              <h1 id="supplierDashboardNavbarHeading">SUPPLIER DASHBOARD</h1>
            )}
          </div>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="ms-auto bg-light"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <SuppliersList />
              <Dropdown
                align="end"
                show={showDropdown}
                onToggle={() => setShowDropdown(!showDropdown)}
              >
                <Dropdown.Toggle as={CustomToggle}>
                  <Button
                    className="hamburger-navbar-dropdown profile ms-4"
                    variant="outline-light"
                  >
                    <FontAwesomeIcon icon={faUserAlt} size="2x" />
                  </Button>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href={getBakerySiteLink()}>
                    <div>
                      {activeParnterName}
                      {bakerySiteRedirectIcon}
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />

                  <Dropdown.Item>
                    <div
                      className="logout"
                      role="button"
                      onClick={() => setShowPasswordChange(true)}
                    >
                      ChangePassword
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Logout />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {showPasswordChange ? (
        <ChangePassword
          show={showPasswordChange}
          onHide={setShowPasswordChange}
        />
      ) : null}
    </div>
  );
};

NavbarHamburger.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
};

export default NavbarHamburger;
